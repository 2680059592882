import axios from '@axios'

export default {
  namespaced: true,
  state: {},
  getters: {},
  mutations: {},
  actions: {
    // sales reports
    calculateReports(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .post('/api/reports/sales-calculate', data)
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCustomReports(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports/sales-report-custom', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchStockReport(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports/stock-report', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchStockReportNonDate(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports/stock-report-non-date', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMonthlyEarningTable(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports/monthly-earning-table', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCustomerSales(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports/sales-report-customer-sales', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSellerSales(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports/sales-report-seller-sales', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchSellerSalesRecord(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports/sales-report-seller-sales-record', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProductSalesRecord(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports/sales-report-product-sales-record', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProductSalesRecordNonDate(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports/sales-report-product-sales-record-non-date', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMonthlyStockTable(ctx, queryParams) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/dashboard/monthly-stock-table', { params: queryParams })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProductStockReport(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports/sales-report-product-stock-record', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCategoryStockReport(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports/sales-report-category-stock-record', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCategoryStockReportPeriod(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports/sales-report-category-stock-record-period', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProductStockReportPeriod(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports/sales-report-product-stock-record-period', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProductStockReportNonDate(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports/sales-report-product-stock-record-non-date', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCategoryStockReportNonDate(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports/sales-report-category-stock-record-non-date', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchEarningExpenseTotals(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports/expense-earning-totals', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchGeneralExpense(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports/general-expense-report', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchMarketingExpense(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports/marketing-expense-report', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchOtherExpense(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports/other-expense-report', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProductBuyRecord(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports/sales-report-product-buy-record', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProductBuyRecordNonDate(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports/sales-report-product-buy-record-non-date', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProductSales(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports/sales-report-product-sales', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchProductSalesDetail(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports/sales-report-product-sales-detail', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchLocationSales(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports/sales-report-location-sales', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCategorySales(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports/sales-report-category-sales', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchUserSalesDetail(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports/user-sales-detail-report', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTopBuyerCustomers(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports/top-buyer-customers-report', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTopBuyerCities(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports/top-buyer-cities-report', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // ----end sales reports
    // target reports
    fetchTargetReports(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports/target-reports', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // --end target reports
    // training reports
    fetchTrainingList(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports/training-list', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTrainingUserReports(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports/training-user-reports', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchTrainingUserHistoryDetail(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports/training-user-history-detail', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    // --end training reports
    // debt reports
    fetchDebtList(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports/debt-list', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
    fetchCustomerDebts(ctx, data) {
      return new Promise((resolve, reject) => {
        axios
          .get('/api/reports/customer-debts', { params: data })
          .then(response => resolve(response))
          .catch(error => reject(error))
      })
    },
  },
}
