<template>
  <div>
    <b-modal
      id="details-modal"
      ref="details-modal"
      title="Ürün Bazında Stok Detayları"
      ok-only
      hide-footer
      size="xl"
    >
      <b-row>
        <b-col md="12">
          <product-stock-record-table
            :filter-options="localFilterOptions"
          />
        </b-col>

      </b-row>
    </b-modal>
    <b-card no-body>
      <b-card-header>
        <!-- title and subtitle -->
        <div>
          <b-card-title class="mb-1">
            Kategori Bazında Ürün Stok Raporu
          </b-card-title>
          <b-card-sub-title />
        </div>
        <!--/ title and subtitle -->

        <!-- badge -->
        <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1">
          <h5 class="font-weight-bolder mb-0 mr-1" />

        </div>
      <!--/ badge -->
      </b-card-header>

      <b-card-body>
        <!-- search input -->
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <div class="d-flex align-items-center">
              <label class="mr-1">Search</label>
              <b-form-input
                v-model="searchTerm"
                placeholder="Search"
                type="text"
                class="d-inline-block"
                @input="handleSearch"
              />
            </div>
          </b-form-group>
        </div>
        <div class="custom-search d-flex justify-content-end">
          <b-form-group>
            <b-button
              variant="primary"
              size="sm"
              @click="toExcel"
            >
              <feather-icon
                icon="FileTextIcon"
                class="mr-50"
              />
              <span class="text-nowrap">{{ $t('Tabloyu Excel Olarak İndir') }}</span>
            </b-button>
          </b-form-group>
        </div>
        <b-table-simple
          v-if="stocksData"

          small
          caption-top
          responsive
        >

          <b-thead>

            <b-tr>
              <b-th>{{ $t('Tarih') }}</b-th>
              <b-th>{{ $t('Kategori') }}</b-th>
              <b-th>{{ $t('Diğer Kategori') }}</b-th>
              <b-th>{{ $t('Toplam Stok') }}</b-th>
              <b-th>{{ $t('Toplam Stok Tutarı') }}</b-th>
            </b-tr>
          </b-thead>
          <b-tbody>
            <b-tr
              v-for="(data, index) in stocksData"
              :key="index"
            >
              <b-td>{{ $format_date(data.date) }}</b-td>
              <b-td>{{ data.category }}</b-td>
              <b-td>{{ data.other_category }}</b-td>
              <b-td><span v-if="data.total_stock!=null">{{ data.total_stock }} </span><span v-else>KAYIT YOK</span></b-td>
              <b-td><span v-if="data.total_stock_price!=null">{{ $staticParams.formatCurrency(data.total_stock_price) }} </span><span v-else>KAYIT YOK</span></b-td>

            </b-tr>

          </b-tbody>
        </b-table-simple>
      </b-card-body>
    </b-card>
  </div>
</template>

<script>
import {
  BCard, BCardBody, BCardHeader, BCardTitle, BCardSubTitle, BFormGroup, BFormInput, BModal, BRow, BCol, BTableSimple, BThead, BTbody, BTr, BTd, BTh, BButton,
} from 'bootstrap-vue'
import store from '@/store'

import useJwt from '@/auth/jwt/useJwt'
import productStockRecordTable from './ProductStockRecordTable.vue'

export default {
  components: {
    BCardHeader,
    BFormGroup,
    BButton,
    BFormInput,
    productStockRecordTable,
    BCard,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTd,
    BTh,
    BModal,
    BRow,
    BCol,
    BCardBody,
    BCardTitle,
    BCardSubTitle,
  },
  props: {
    filterOptions: {
      type: Object,
      required: true,
    },
  },
  data() {
    return {
      stocksData: null,
      localFilterOptions: null,
      pageLength: 20,
      dir: false,
      isLoading: false,

      searchTerm: '',
    }
  },
  computed: {

  },
  watch: {
    userid() {
      this.fetchData()
    },
    filterOptions() {
      this.localFilterOptions = JSON.parse(JSON.stringify(this.filterOptions))
      this.fetchData()
    },
  },
  created() {
    this.localFilterOptions = JSON.parse(JSON.stringify(this.filterOptions))
    this.fetchData()
  },
  methods: {
    toExcel() {
      const excelUrl = `${process.env.VUE_APP_API_URL}/api/reports/sales-report-category-stock-record-period?period_type=${this.localFilterOptions.period_type}&other_category=${this.localFilterOptions.other_category}&category=${this.localFilterOptions.category}&search_term=${this.searchTerm}&order_column=${this.order_column}&ordering=${this.sort}&start_year=${this.localFilterOptions.start_year}&end_year=${this.localFilterOptions.end_year}&start_month=${this.localFilterOptions.start_month}&end_month=${this.localFilterOptions.end_month}&type=excel&token=${useJwt.getToken()}`
      window.location.href = `${excelUrl}`
    },
    showDetails(rowData) {
      this.localFilterOptions.category = rowData.category
      this.localFilterOptions.other_category = rowData.other_category
      this.$refs['details-modal'].show()
    },
    fetchData() {
      store
        .dispatch('app-reports/fetchCategoryStockReportPeriod', {
          user_id: this.userid,
          ordering: this.sort,
          data_count: this.pageLength,
          search_term: this.searchTerm,
          order_column: this.order_column,
          page: this.page,
          start_year: this.localFilterOptions.start_year,
          start_month: this.localFilterOptions.start_month,
          end_year: this.localFilterOptions.end_year,
          end_month: this.localFilterOptions.end_month,
          user_code: this.localFilterOptions.seller_code,
          category: this.localFilterOptions.category,
          other_category: this.localFilterOptions.other_category,
          period_type: this.localFilterOptions.period_type,
        })
        .then(response => {
          const { data } = response.data
          this.stocksData = data
        })
        .catch(() => {
        })
    },
    // eslint-disable-next-line no-unused-vars
    handleSearch(searching) {
      this.fetchData()
    },
    handleChangePage(page) {
      this.page = page
      this.fetchData()
    },
    // eslint-disable-next-line no-unused-vars
    handlePageChange(active) {
      this.fetchData()
    },
    onSortChange(params) {
      this.order_column = params[0].field
      this.sort = params[0].type
      this.fetchData()
    },
  },

}
</script>
