<template>
  <div>
    <b-row>
      <b-col
        v-if="isLoading"
        md="12"
      >
        <h1>VERİLER HESAPLANIYOR LÜTFEN BEKLEYİNİZ..</h1>
      </b-col>
      <b-col
        md="12"
        sm="12"
      >
        <b-card>

          <b-form

            @submit.prevent="handleSubmit(onSubmit)"
            @reset.prevent="resetForm"
          >
            <b-row>
              <b-col md="4">

                <b-form-group
                  :label="$t('Başlangıç Ay/Yıl')"
                  label-for="target_year"
                >  <b-input-group>

                  <b-form-select
                    v-model="filterData.start_year"
                    :options="targetYears"
                  />
                  <b-input-group-append>
                    <b-form-select
                      v-model="filterData.start_month"
                      :options="targetMonts"
                      text-field="name"
                      value-field="number"
                    />
                  </b-input-group-append>
                </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  :label="$t('Bitiş Ay/Yıl')"
                  label-for="target_year"
                >  <b-input-group>

                  <b-form-select
                    v-model="filterData.end_year"
                    :options="targetYears"
                  />
                  <b-input-group-append>
                    <b-form-select
                      v-model="filterData.end_month"
                      :options="targetMonts"
                      text-field="name"
                      value-field="number"
                    />
                  </b-input-group-append>
                </b-input-group>
                </b-form-group>
              </b-col>
              <b-col md="4">
                <b-form-group
                  :label="$t('Periyot')"
                  label-for="target_year"
                >  <b-input-group>

                  <b-form-select
                    v-model="filterData.period_type"
                    :options="periodTypes"
                  />

                </b-input-group>
                </b-form-group>
              </b-col>
            </b-row>
            <b-button
              v-if="!isLoading"
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              @click="calculateData"
            >
              Hesapla ve Filtrele
            </b-button>
            <div class="d-flex mt-2">

              <b-button
                v-if="!isLoading"
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                @click="fetchData"
              >
                Filtrele
              </b-button>
            </div>
          </b-form>

        </b-card>

      </b-col>
      <b-col md="3">
        <statistic-card-vertical
          color="secondary"
          icon="FlagIcon"
          :statistic="$staticParams.formatCurrency(reportData.total_stock_price)+' TL'"
          statistic-title="Tüm Mevcut Stokların Toplam Değeri"
        />
      </b-col>
      <b-col
        v-if="reportData"
        md="12"
      >

        <b-row>
          <b-col md="12">
            <category-stock-record-table
              :filter-options="filterOptions"
            />
          </b-col>
          <b-col md="12">
            <product-stock-record-table
              :filter-options="filterOptions"
            />
          </b-col>

        </b-row>
      </b-col>
      <b-col md="12">
        <b-card no-body>
          <b-card-header>
            <!-- title and subtitle -->
            <div>
              <b-card-title class="mb-1">
                Son 2 Yıl Stok Geçmişi Tablosu (Her Ayın 1'i Baz Alınır)
              </b-card-title>
              <b-card-sub-title />
            </div>
            <!--/ title and subtitle -->

            <!-- badge -->
            <div class="d-flex align-items-center flex-wrap mt-sm-0 mt-1" />
            <!--/ badge -->
          </b-card-header>
          <b-card-body>
            <div class="custom-search d-flex justify-content-end">
              <b-form-group>
                <b-button
                  variant="primary"
                  size="sm"
                  @click="toExcel"
                >
                  <feather-icon
                    icon="FileTextIcon"
                    class="mr-50"
                  />
                  <span class="text-nowrap">{{ $t('Tabloyu Excel Olarak İndir') }}</span>
                </b-button>
              </b-form-group>
            </div>
            <b-table-simple
              v-if="monthlyStockTable"

              small
              caption-top
              responsive
            >

              <b-thead>

                <b-tr>
                  <b-th>{{ $t('Yıl') }}</b-th>
                  <b-th>{{ $t('Ay') }}</b-th>
                  <b-th>{{ $t('Toplam Stok Bedeli') }}</b-th>
                </b-tr>
              </b-thead>
              <b-tbody>
                <b-tr
                  v-for="(data, index) in monthlyStockTable"
                  :key="index"
                >
                  <b-td>{{ data.year }}</b-td>
                  <b-td>{{ $staticParams.getMonthName(data.month) }}</b-td>
                  <b-td>  <span v-if="data.total_stock_price!=null">{{ $staticParams.formatCurrency(data.total_stock_price ) }}</span>
                    <span v-else>VERİ MEVCUT DEĞİL</span>
                  </b-td>

                </b-tr>

              </b-tbody>
            </b-table-simple>
          </b-card-body>
        </b-card>
      </b-col>
    </b-row>
    <div />
  </div></template>

<script>
import {
  BRow, BCol, BFormGroup, BFormSelect, BInputGroup, BInputGroupAppend, BButton, BCard, BForm, BTableSimple, BThead, BTbody, BTr, BTd, BTh, BCardBody, BCardHeader, BCardTitle, BCardSubTitle,
} from 'bootstrap-vue'
import store from '@/store'
import Ripple from 'vue-ripple-directive'
import { ref, onUnmounted } from '@vue/composition-api'
import { avatarText } from '@core/utils/filter'
import StatisticCardVertical from '@core/components/statistics-cards/StatisticCardVertical.vue'
import useJwt from '@/auth/jwt/useJwt'
import reportsStoreModule from '../reportsStoreModule'
import categoryStockRecordTable from './CategoryStockRecordTable.vue'
import productStockRecordTable from './ProductStockRecordTable.vue'

export default {
  components: {
    BRow,
    BButton,
    BFormGroup,
    BFormSelect,
    BCard,
    BForm,
    BCardBody,
    BCardHeader,
    BCardTitle,
    BCardSubTitle,
    BTableSimple,
    BThead,
    BTbody,
    BTr,
    BTd,
    BTh,
    BInputGroup,
    BInputGroupAppend,
    BCol,
    productStockRecordTable,
    categoryStockRecordTable,
    StatisticCardVertical,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      isLoading: false,
      periodTypes: [
        { text: 'Günlük', value: 'daily' },

        { text: 'Haftalık', value: 'weekly' },
        { text: 'Aylık', value: 'monthly' },
      ],
      filterData: {
        seller_codes: [0],
        start_year: 2015,
        start_month: 0,
        end_year: 2015,
        end_month: 0,
        period_type: 'monthly',
        type: '',
      },
      reportData: null,

    }
  },
  computed: {
    filterOptions() {
      return {
        start_year: this.filterData.start_year,
        start_month: this.filterData.start_month,
        end_year: this.filterData.end_year,
        end_month: this.filterData.end_month,
        seller_code: '',
        location: '',
        buyer_name: '',
        product_code: '',
        random_chars: this.filterData.random_chars,
        period_type: this.filterData.period_type,

      }
    },
    targetMonts() {
      const arr = [{ number: 0, name: 'Tümü' }]
      this.$staticParams.monthNames.forEach(val => {
        arr.push(val)
      })
      return arr
    },

  },
  created() {
    const d = new Date()
    const thisYear = d.getFullYear()

    this.filterData.start_year = thisYear
    this.filterData.end_year = thisYear
    this.filterData.start_month = 11
    this.filterData.end_month = 11
    if (this.$route.query.month) {
      // eslint-disable-next-line radix
      this.filterData.target_month = parseInt(this.$route.query.month)
      // eslint-disable-next-line radix
      this.targetDetailMonth = parseInt(this.$route.query.month)
    }
    if (this.$route.query.year) {
      // eslint-disable-next-line radix
      this.filterData.start_year = parseInt(this.$route.query.year)
      this.targetDetailMonth = 0
    }
    if (this.$route.query.type) {
      if (this.$route.query.type === 'current') {
        this.targetDetailMonth = 13
      }
    } else {
      this.filterData.type = ''
    }
    this.fetchData()
  },

  methods: {
    toExcel() {
      const excelUrl = `${process.env.VUE_APP_API_URL}/api/dashboard/monthly-stock-table?type=excel&token=${useJwt.getToken()}`
      window.location.href = `${excelUrl}`
    },
    changeRandomChars() {
      this.filterData.random_chars = Math.random()
    },

    showSuccessMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarılı'), {
        title: this.$t('İşlem Başarılı'),
        variant: 'success',
        solid: false,
      })
    },
    showErrorMessage() {
      this.$bvToast.toast(this.$t('İşlem Başarısız'), {
        title: this.$t('İşlem Başarısız'),
        variant: 'danger',
        solid: false,
      })
    },
    fetchData() {
      this.changeRandomChars()
      store
        .dispatch('app-reports/fetchStockReport', this.filterData)
        .then(response => {
          this.reportData = response.data.data
        })
        .catch(() => {
        })
    },
    calculateData() {
      this.isLoading = true
      store
        .dispatch('app-reports/calculateReports', this.filterData)
        .then(() => {
          this.isLoading = false
          this.fetchData()
          this.showSuccessMessage()
        })
        .catch(() => {
          this.isLoading = false
        })
    },
  },
  setup() {
    const APP_STORE_MODULE_NAME = 'app-reports'

    // Register module
    if (!store.hasModule(APP_STORE_MODULE_NAME)) store.registerModule(APP_STORE_MODULE_NAME, reportsStoreModule)

    // UnRegister on leave
    onUnmounted(() => {
      if (store.hasModule(APP_STORE_MODULE_NAME)) store.unregisterModule(APP_STORE_MODULE_NAME)
    })

    // eslint-disable-next-line no-unused-vars
    const targetsData = ref(null)
    const targetYear = ref(2015)
    const targetYearStart = ref(2015)
    const targetYears = ref([])
    const d = new Date()
    const thisYear = d.getFullYear()

    targetYear.value = thisYear
    const arrTarget = []
    // eslint-disable-next-line no-plusplus
    for (let i = targetYearStart.value; i <= thisYear + 1; i++) {
      arrTarget.push({ text: i, value: i })
    }
    targetYears.value = arrTarget

    const usersData = ref(null)
    store
      .dispatch('app/fetchReportUsers', {
        list_type: 'all',

      })
      .then(response => {
        const { data } = response.data
        const arr = []
        data.forEach(val => {
          arr.push({ label: `${val.seller_name} `, value: val.seller_code })
        })
        arr.unshift({ label: 'Tümü', value: 0 })
        usersData.value = arr
      })
      .catch(() => {
      })
    const monthlyStockTable = ref(null)
    store.dispatch('app-reports/fetchMonthlyStockTable', { list_type: 'all' })
      .then(response => {
        monthlyStockTable.value = response.data.data
      })
    return {
      monthlyStockTable,
      // Filter
      avatarText,
      usersData,
      targetYears,
      // UI
      // Extra Filters
    }
  },
}
</script>

<style lang="scss" scoped>
.per-page-selector {
  width: 90px;
}
.vgt-table {
    font-size: 13px;
    border-collapse: collapse;
    width: 100%;
    max-width: 100%;
    table-layout: auto;
}
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-autosuggest.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';
</style>
<style lang="scss">
@import '@core/scss/vue/libs/vue-flatpicker.scss';
</style>
<style lang="scss" >
@import '@core/scss/vue/libs/vue-good-table.scss';
</style>
